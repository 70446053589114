import React, { useEffect } from 'react';
import {
    Button, TabPanel, Collapse,
    Card, Typography, Select, Option, IconButton,
    ListItem, List,
} from '@material-tailwind/react';
import MaterialIcon from 'components/material-icon';

export default function ThemingTab({ tabValue, themesData, themesDataError, setShowThemeDialog, 
        setShowAnswerThemingDialog, setShowThemeAnswersDialog, setThemeEdit, setAnswerTheming, 
        setShowThemeDeleteDialog, themesState, setThemesState, surveyRespondentAnswers, 
        surveyRespondentAnswersError, modQShown, setModQShown, surveyQuestions, themesSurveyReport, 
        themesPage, previousThemesPage, nextThemesPage })
        {
        const themeCount = (answerId) => {
            const answer = themesSurveyReport.report.find(a => a.answer_id === answerId);
            const answerThemes = answer?.themes;
            const themeAmount = Object.keys(answerThemes).length;
            return themeAmount;
        }


    return (
        <>
            <TabPanel value={tabValue} className=''>
                {(themesData &&
                    <>
                        <Card className="px-2 pt-4 pb-6 min-h-[50vh] bg-white" shadow={true}>
                            <Card className='border border-gray-700 mb-2 font-body p-2 text-gray-900'>
                                In theme manager you can create, add and apply themes to survey respondent answers. Any answer can have multiple themes.
                                <List className='font-body text-gray-800 py-0'>
                                    <ListItem className='py-0 pl-6'>&bull; Use the Create Theme button to add a new theme.</ListItem>
                                    <ListItem className='py-0 pl-6'>&bull; Use the Edit Themes button to see current themes, from there you can edit, delete or apply a theme:</ListItem>
                                    <ListItem>
                                        <List className='font-body text-gray-800 py-0'>
                                            <ListItem className='py-0 pl-6'>&ordm; Edit will allow you to change the name and description of an existing theme;</ListItem>
                                            <ListItem className='py-0 pl-6'>&ordm; Delete will remove a theme;</ListItem>
                                            <ListItem className='py-0 pl-6'>&ordm; Apply theme allows you to select a question (preset or Gobby®) and apply that theme to the answers.</ListItem>
                                        </List>
                                    </ListItem>
                                    <ListItem>&bull; Use Preset Answers Button to choose a preset answer to apply theme(s) to individual responses</ListItem>
                                    <ListItem>&bull; Use Gobby® Responses Button to choose a preset answer to apply theme(s) to individual responses</ListItem>
                                </List>
                            </Card>

                            <div className="flex flex-row-reverse mb-4">
                                {((themesState === 'edit') &&
                                <Button onClick={() => {
                                        setThemeEdit(undefined);
                                        setShowThemeDialog(true);
                                    }}
                                    color={themesState === 'response' ? 'white' : 'white'}                                    
                                    className="
                                    rounded-full
                                    px-2 py-2 
                                    text-sm
                                    border
                                    border-gray-500
                                    hover:text-blue-500
                                    hover:border-blue-500
                                    font-body font-thin normal-case
                                    mr-4 mb-2"
                                    variant="filled">
                                    Create Theme
                                </Button>
                                )}
                                <Button onClick={() => setThemesState('edit')}
                                    color={themesState === 'response' ? 'white' : 'white'}
                                    className="
                                    rounded-full
                                    px-2 py-2 
                                    text-sm
                                    border
                                    border-gray-500
                                    hover:text-blue-500
                                    hover:border-blue-500
                                    font-body font-thin normal-case
                                    mr-4 mb-2"
                                    variant="filled">
                                    Edit Themes
                                </Button>
                                <Button onClick={() => setThemesState('preset')}
                                    color={themesState === 'response' ? 'white' : 'white'}
                                    className="
                                    rounded-full
                                    px-2 py-2 
                                    text-sm
                                    border
                                    border-gray-500
                                    hover:text-blue-500
                                    hover:border-blue-500
                                    font-body font-thin normal-case
                                    mr-4 mb-2"
                                    variant="filled">
                                    Preset Answers
                                </Button>
                                {(surveyRespondentAnswers &&    
                                <Button onClick={() => setThemesState('response')}
                                    color={themesState === 'response' ? 'white' : 'white'}
                                    className="
                                    rounded-full
                                    px-2 py-2 
                                    text-sm
                                    border
                                    border-gray-500
                                    hover:text-blue-500
                                    hover:border-blue-500
                                    font-body font-thin normal-case
                                    mr-4 mb-2"
                                    variant="filled">
                                    Gobby® Responses
                                </Button>
                                )}
                            </div>

                            {(((themesState === 'preset') || (themesState === 'response')) && surveyQuestions &&
                                <Select label="Select a question from the list" onChange={(e) => {
                                    const indexInt = parseInt(e);
                                    setModQShown(indexInt);
                                }}>
                                    {surveyQuestions.map((q, index) => (
                                        <Option key={q.id} value={`${index}`}>Question {q.position}: {q.text}</Option>
                                    ))}
                                </Select>
                            )}
                            <table className='font-body md:table-fixed border-collapse'>
                                {(themesState === 'edit' &&
                                <>
                                    <thead className='text-left text-sm sm:text-lg border-b border-gray-300 text-blue-gray-700'>
                                        <tr>
                                            <th className='w-5/12 pl-1'>Theme Name</th>
                                            <th className='w-5/12'>Description</th>
                                            <th className=''>
                                                &nbsp;
                                            </th>
                                            <th className='flex justify-end pb-2'>
                                            <IconButton color="white" type="button" variant="filled" className="border border-gray-500"
                                                disabled={themesPage === 1} onClick={previousThemesPage}>
                                                <MaterialIcon name="arrow_back_ios_new" size="lg"/>
                                            </IconButton>
                                            <IconButton color="white" type="button" variant="filled" className='ml-2 border border-gray-500'
                                                disabled={themesData?.pager?.current_page === themesData?.pager?.last_page} 
                                                onClick={nextThemesPage}>
                                                <MaterialIcon name="arrow_forward_ios" size="lg"/>
                                            </IconButton>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {themesData?.data?.map((theme, index) => (
                                            <tr className='border-b border-gray-300 odd:bg-gray-100 text-blue-gray-700' key={theme.id}>
                                                <td className='text-sm w-4/12'>{theme.name}</td>
                                                <td className='text-sm w-4/12'>{theme.description}</td>
                                                {(
                                                <td className='text-right w-2/12'>
                                                    <Button type="button"
                                                        variant="filled"
                                                        color='white'
                                                        className="
                                                            rounded-full 
                                                            text-sm
                                                            border
                                                            border-gray-500
                                                            sm:px-4 sm:py-1
                                                            px-2 py-1
                                                            hover:border-1
                                                            hover:border-blue-500
                                                            hover:text-blue-500
                                                            font-body font-thin normal-case
                                                            my-1"
                                                        onClick={() => {
                                                            setThemeEdit(theme);
                                                            setModQShown(undefined);
                                                            setShowThemeAnswersDialog(true);
                                                        }}>
                                                            Apply Theme
                                                    </Button>
                                                </td>
                                                )}
                                                <td className='text-right'>
                                                    <Button type="button"
                                                        variant="filled"
                                                        color='white'
                                                        className="
                                                            rounded-full 
                                                            text-sm
                                                            border
                                                            border-gray-500
                                                            sm:px-4 sm:py-1
                                                            px-2 py-1
                                                            hover:border-1
                                                            hover:border-blue-500
                                                            hover:text-blue-500
                                                            font-body font-thin normal-case
                                                            my-1"
                                                        onClick={() => {
                                                            setThemeEdit(theme);
                                                            setShowThemeDialog(true);
                                                        }}>
                                                        Edit
                                                    </Button>
                                                </td>
                                                <td className='text-right md:pr-1'>
                                                    <Button type="button"
                                                        variant="filled"
                                                        color='white'
                                                        className="
                                                            rounded-full 
                                                            text-sm
                                                            sm:px-4 sm:py-1
                                                            px-2 py-1
                                                            border
                                                            border-gray-500
                                                            hover:bg-red-700
                                                            hover:border-red-900
                                                            hover:text-white
                                                            font-body font-thin normal-case"
                                                        onClick={() => {
                                                            setThemeEdit(theme);
                                                            setShowThemeDeleteDialog(true);
                                                        }}>
                                                        Delete
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                        )}
                                    </tbody>
                                </>
                                )}
                                {(themesState === 'preset' &&
                                <>
                                    <thead className='text-left text-lg border-b border-gray-500 text-blue-gray-700'>
                                        <tr>
                                            <th className='w-4/6 pl-1 pt-4'>Preset Response</th>
                                            <th className='w-1/6 pl-1 pt-4' align="center">Theme Count</th>
                                            <th className='w-1/6'>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {surveyQuestions[modQShown]?.preset_answers.map((answer, index) => (
                                            <tr className='border-b border-gray-300 text-blue-gray-700  odd:bg-gray-100' key={answer.id}>
                                                <td className=''>{answer.text}</td>
                                                <td align="center" className=''><Typography className='text-sm border text-white bg-blue-500 w-[30px] h-[30px] py-1 px-2 rounded-full font-bold'>{themeCount(answer.nanoid)}</Typography></td>
                                                <td className='text-right md:pr-4'>
                                                    <Button type="button"
                                                        variant="gradient"
                                                        color="white"
                                                        className="
                                                            rounded-full 
                                                            text-sm 
                                                            my-2
                                                            px-4 py-1
                                                            hover:border-blue-500
                                                            hover:text-blue-500
                                                            border
                                                            border-gray-500
                                                            font-body font-thin normal-case"
                                                        onClick={() => {
                                                            setAnswerTheming(answer.nanoid);
                                                            setShowAnswerThemingDialog(true);
                                                        }}>
                                                        Add Themes
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                        )}
                                    </tbody>
                                </>
                                )}
                                {(themesState === 'response' &&
                                <>
                                    <thead className='text-left text-lg border-b border-gray-500 text-blue-gray-700'>
                                        <tr>
                                            <th className='w-4/6 pl-1 pt-4'>Gobby® Response</th>
                                            <th className='w-1/6 pl-1 pt-4 text-center'>Theme Count</th>
                                            <th className='w-1/6'>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody className=' text-blue-gray-700'>
                                        {surveyRespondentAnswers?.[modQShown]?.respondent_answers.map((answer, index) => (
                                            <tr className='border-b border-gray-300 text-blue-gray-700  odd:bg-gray-100' key={answer.id}>
                                                <td className=''>{answer.text}</td>
                                                <td align="center" className=''><Typography className='text-sm border text-white bg-blue-500 w-[30px] h-[30px] py-1 px-2 rounded-full font-bold'>{themeCount(answer.nanoid)}</Typography></td>
                                                <td className='text-right md:pr-4'>
                                                    <Button type="button"
                                                       variant="gradient"
                                                       color="white"
                                                       className="
                                                           rounded-full 
                                                           text-sm 
                                                           my-2
                                                           px-4 py-1
                                                           hover:border-1
                                                           hover:border-blue-500
                                                           hover:text-blue-500
                                                           border
                                                           border-gray-500
                                                           font-body font-thin normal-case"
                                                        onClick={() => {
                                                            setAnswerTheming(answer.nanoid);
                                                            setShowAnswerThemingDialog(true);
                                                        }}>
                                                        Add Themes
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                        )}
                                    </tbody>
                                </>
                                )}
                            </table>
                        </Card>
                    </>
                )}
                {(themesDataError &&
                    <Typography>Error loading themes!</Typography>
                )}
            </TabPanel>
        </>
    );
}